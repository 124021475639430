@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 47.4% 11.2%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 47.4% 11.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;
	}

	body {
		@apply bg-background;
	}
}

html {
	scroll-behavior: smooth;
}

*::-webkit-scrollbar {
	@apply w-1 h-1;
}

*::-webkit-scrollbar-thumb {
	@apply bg-[#E8E9EA] rounded-full;
}

.bg-gradient {
	background: linear-gradient(180deg, #0078ff 0%, #2189ff 100%);
}

.bg-gradient-image {
	background: url(/pattern.webp), linear-gradient(180deg, #0078ff 0%, #2189ff 100%);
}

.bg-gradient.bg-opacity-5 {
	background: linear-gradient(180deg, rgba(0, 120, 255, 0.05) 0%, rgba(33, 137, 255, 0.05) 100%);
}

.shadow-gradient {
	box-shadow: 0px 4px 24px 0px #1877f240;
}

.shadow-gradient-light {
	box-shadow: 0px 4px 24px 0px #1877f212;
}

.fade-out::after {
	content: '';
	height: 100%;
	width: 140px;
	display: flex;
	position: absolute;
	z-index: 1;
	bottom: 0;
	border-radius: 0 0 1.5rem 1.5rem;
	pointer-events: none;
	@apply end-0 rtl:bg-gradient-to-r ltr:bg-gradient-to-l from-[#f3f8ff] to-[#ffffff00];
}

.country-flag {
	mask: url(/flags/flag.svg) no-repeat center;
}
